
import React, { useEffect, useState } from 'react';

import CustomStore from 'devextreme/data/custom_store';
import DataGrid, { FilterRow, Pager, Paging, Editing, DataGridTypes, RequiredRule, StringLengthRule, Column, Lookup, FormItem, Export } from 'devextreme-react/data-grid';

// @ts-ignore
import { FetchAPIPromise, PostAPIPromise, DeleteAPIPromise, onExportingFileName, isAccessReadOnly, formatComponentPageName } from '../BaseComponent';
import * as $ from 'jquery';

import { Sch_DictStateDTO } from '../../types/sch_-dict-state-dto.js';
import { Sch_AOR } from '../../types/sch_aor.js';

function DictionaryCityContainer() {
    const [states, setStates] = useState<Array<Sch_DictStateDTO>>([]);
    const [aors, setAors] = useState<Array<Sch_AOR>>([]);

    formatComponentPageName('City Management');

    const customDataSource = new CustomStore({
        key: 'cityCode',
        load: () => {
            return FetchAPIPromise('DictionaryCity');
        },
        insert: (values) => {
            return PostAPIPromise('DictionaryCity', values);
        },
        remove: (key) => {
            return DeleteAPIPromise('DictionaryCity/' + key)
                .then((result: any) => {
                    if (result.status == -2) {
                        alert('City is referenced in the system and cannot be deleted. Consider inactivating.')
                    }
                });
        },
        update: (key, values) => {
            return PostAPIPromise('DictionaryCity', values);
        }
    });

    useEffect(() => {
        async function fetchData() {
            const stateData = await FetchAPIPromise('DictionaryState');
            const aorData = await FetchAPIPromise('DictionaryAOR');

            setStates(stateData);
            setAors(aorData);
        }

        fetchData();
    }, []);

    const onInitNewRow = (e: DataGridTypes.InitNewRowEvent) => {
        // Set active value default to true
        e.data.isActived = true;
    }

    const onRowUpdating = (options: DataGridTypes.RowUpdatingEvent) => {
        options.newData = $.extend({}, options.oldData, options.newData);
    }

    const onExporting = (e: DataGridTypes.ExportingEvent) => {
        onExportingFileName(e, "cityManagment");
    }

    const isReadOnly = isAccessReadOnly();

    //console.log('Begin Render');

    return (
        <div className="container">
            <h1>City Management</h1>

            <div className="container">
                <DataGrid dataSource={customDataSource}
                    showBorders={true} allowColumnResizing={true}
                    keyExpr="cityCode" onInitNewRow={onInitNewRow} onRowUpdating={onRowUpdating} onExporting={onExporting}>
                    <Export enabled={true} />
                    <Editing mode="popup" allowAdding={!isReadOnly} allowUpdating={!isReadOnly} allowDeleting={!isReadOnly} />
                    <FilterRow visible={true} />
                    <Paging defaultPageSize={10} />
                    <Pager showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 20]}
                        showInfo={true} />

                    <Column caption="City Code" dataField="cityCode">
                        <RequiredRule />
                        <StringLengthRule max={50} />
                    </Column>
                    <Column caption="City Name" dataField="cityName">
                        <RequiredRule />
                        <StringLengthRule max={200} />
                    </Column>
                    <Column
                        caption="State Code"
                        dataField="stateCode">
                        <RequiredRule />
                        <Lookup allowClearing={true} dataSource={states} displayExpr="name" valueExpr="name" />
                    </Column>
                    <Column caption="State Name"
                        dataField="stateName">
                        <FormItem visible={false} />
                    </Column>
                    <Column caption="AOR"
                        dataField="aorCode">
                        <RequiredRule />
                        <Lookup allowClearing={true} dataSource={aors} displayExpr="aorCode" valueExpr="aorCode" />
                    </Column>
                    <Column caption="Active"
                        dataField="isActived" />
                </DataGrid>
                <br />
            </div>
        </div>
    );
}

export default DictionaryCityContainer;