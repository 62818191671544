
import React from 'react';
import * as moment from 'moment';
import BaseComponent, { FetchAPIPromise, PostAPIPromise, DeleteAPIPromise } from "../BaseComponent";
import DataGrid, { Column, FormItem, FilterRow, Pager, Paging, Lookup, Editing, Sorting, RequiredRule, StringLengthRule, Export } from 'devextreme-react/data-grid';
import Form, { Item, EmptyItem, Label } from "devextreme-react/form";
import CustomStore from 'devextreme/data/custom_store';
import { Link } from 'react-router-dom';
import Button from 'devextreme-react/button';
import * as $ from 'jquery'

export class NonbillTypeContainer extends BaseComponent {
    static displayName = NonbillTypeContainer.name;

    constructor(props) {
        super(props);

        this.state = {

            nonbillTypes: []
        };
    }

    componentDidMount = async () => {
        super.componentDidMount();
        this.fetchData();
    }

    GetComponentPageName = () => {
        return ("Nonbill Types");
    }

    GetDropDownData = async () => {
        const nonbillTypeData = await this.FetchAPI('EmployeeNonbillHours/GetNonbillTypes');
        //console.log(officeData);

        await this.setState({
            nonbillTypes: nonbillTypeData,
        });
    }

    onEditorPreparing = (e) => {
        //console.log(e);

        if (e.dataField == 'code') {
            e.editorOptions.readOnly = e.row && !e.row.isNewRow;
        }
    }

    allowDeleting = (options) => {
        return options.row.data;
    }

    allowUpdating = (options) => {
        return options.row.data;
    }


    customDataSource = new CustomStore({
        key: 'code',
        load: (loadOptions) => {
            //console.log('Load', loadOptions);
            return FetchAPIPromise('NonbillTypeManagement/GetNonbillTypes');
        },
        insert: async (values) => {
            var returnValue = await PostAPIPromise('NonbillTypeManagement', values);

            if (returnValue.status == -1) {
                throw new Error(returnValue.message);
            }
            else {
                return returnValue
            }
        },
        update: async (key, values) => {
            var returnValue = await PostAPIPromise('NonbillTypeManagement', values);

            if (returnValue.status == -1) {
                throw new Error(returnValue.message);
            }
            else {
                return returnValue
            }
        },
        remove: (key) => {
            return DeleteAPIPromise('NonbillTypeManagement/' + key);
        }
    });

    onRowUpdating(options) {
        options.newData = $.extend({}, options.oldData, options.newData);
    }

    render() {
        return (
            <div className="container">
                <h1>Nonbill Type Management</h1>
                <br />
                <Link to={`/nonbill-hours`}>
                    <Button text="Nonbill Hours" />
                </Link>
                <br />
                <br />
                <DataGrid dataSource={this.customDataSource} key="code"
                    showBorders={true} allowColumnResizing={true}
                    ref={ref => this.dataGrid = ref} onRowUpdating={this.onRowUpdating} onEditorPreparing={this.onEditorPreparing} onExporting={this.onExporting}> 

                    <Export enabled={true} fileName={"nonbillTypes"} />
                    <Editing mode="popup" allowAdding={true} allowUpdating={this.allowUpdating} allowDeleting={this.allowDeleting}>
                    </Editing>

                    <FilterRow visible={true} />
                    <Paging defaultPageSize={10} />
                    <Pager showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 20]}
                        showInfo={true} />

                    <Column caption="Nonbill Type" dataField="code">
                        <RequiredRule />
                        <StringLengthRule max={20} />
                    </Column>
                    <Column caption="Name" dataField="name">
                        <RequiredRule />
                        <StringLengthRule max={200} />
                    </Column>
                    <Column caption="Project Code" dataField="projectCode">
                        <RequiredRule />
                        <StringLengthRule max={50} />
                    </Column>
                    <Column caption="Project Pay Code" dataField="projectPayCode">
                        <RequiredRule />
                        <StringLengthRule max={10} />
                    </Column>
                    <Column caption="Project Pay Overtime Code" dataField="projectPayOvertimeCode">
                        <RequiredRule />
                        <StringLengthRule max={10} />
                    </Column>
                    <Column caption="PLC" dataField="plc">
                        <StringLengthRule max={100} />
                    </Column>
                    <Column caption="Created By" dataField="createUser" visible={false}>
                        <FormItem visible={false} />
                    </Column>
                    <Column caption="Created At" dataField="createDate" dataType="datetime" format="MM/dd/yy, HH:mm" visible={false}>
                        <FormItem visible={false} />
                    </Column>
                    <Column caption="Updated By" dataField="updateUser">
                        <FormItem visible={false} />
                    </Column>
                    <Column caption="Updated At" dataField="updateDate" dataType="datetime" format="MM/dd/yy, HH:mm">
                        <FormItem visible={false} />
                    </Column>
                </DataGrid>
                <br />
            </div>
        );
    }
}